<template>
  <span>{{text}}</span>
</template>
<script>
import { openBetLabels } from '@/configs/market.config'

export default {
  name: 'NumberType',
  props: ['type'],
  computed: {
    text() {
      // const betType = marketOpenBets.find((bet)=>{
      //   return bet.code === this.type
      // })

      // if(betType) {
      //   return betType.text
      // }else{
      //   return this.type
      // }
      return openBetLabels?.[this.type] || this.type
    }
  }
}
</script>
