import moment from 'moment-timezone';
moment.tz.setDefault("Asia/Bangkok");
moment.locale('th')
moment.updateLocale('th', {
  week: {
    dow : 1
  }
})

export default moment
