<template>
  <span class="badge font-weight-normal" :class="statusClass">{{statusText}}</span>
</template>
<script>
export default {
  name: 'TicketStatus',
  props: ['status'],
  computed: {
    /**
     * Recoding กำลังบันทึกเลข
     * Accepted รับแทง
     * Canceled ยกเลิก
     * Refund คืนเงิน
     * Processing กำลังออกผล
     * Paying กำลังจ่ายเงิน
     * Completed ออกผลแล้ว
     */
    statusClass() {
      return {
        Recoding: 'badge-warning',
        Accepted: 'badge-success',
        Canceled: 'badge-danger',
        Refund: 'badge-warning',
        Processing: 'badge-info',
        Paying: 'badge-success',
        Completed: 'badge-primary'
      }[this.status]
    },
    statusText() {
      return {
        Recoding: 'กำลังบันทึกเลข',
        Accepted: 'รับแทง',
        Canceled: 'ยกเลิก',
        Refund: 'คืนเงิน',
        Processing: 'กำลังออกผล',
        Paying: 'กำลังจ่ายเงิน',
        Completed: 'จ่ายเงินแล้ว'
      }[this.status]
    }
  }
}
</script>
<style lang="scss" scoped>
.badge {
  font-size: 100% !important;
}
</style>
